<template>
<div>

</div>
</template>

<script>
export default {
  name: "Demo",
  // created() {
  //   this.$router.push("http://localhost:63343/aiocs.web.ihzsr.cn/clientdemo/index.html")
  // }
}
var c = document.createElement('script');
var url = window.location.hostname;
c.src = "http://www.local.aiocs.cn/api/vclientV3?tokenkey=i6UzXHJbnYMphPelKyTuqz8As%2BvE%2FeGefmVHiZgDlW8dujpSAIp2ZQ%3D%3D&website="+url;
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(c, s);


window.onmousewheel = smallScroll
function smallScroll(){
  var smallView = document.getElementsByClassName('srSmallView')[0];
  if (smallView.style.display == "none"){
    var srSmallTaskView = document.getElementsByClassName('srSmallTaskView')[0];
    srSmallTaskView.scrollIntoView();
  }
  if (smallView.style.display != "none"){
    var srSmallTaskView = document.getElementsByClassName('srSmallTaskView')[0];
  }
}
</script>

<style scoped>

</style>
